import { ref, onUnmounted, onMounted } from '@vue/composition-api';
import axios from '@/plugins/axios';
import { debounce } from '@/utils';

const stopwatch = ref({
  id: '',
  state: 'stopped', // 'stopped' | 'running',
});

let intervalObject;

export default function useStopwatch(id, interval, username, onUpdate = () => {}) {
  clearInterval(intervalObject);

  stopwatch.value.id = id;

  function sendTimeSpent() {
    if (stopwatch.value.state === 'running' && !username.endsWith('@golem.ai')) {
      return axios.patch(`/tenders/${id}`, {
        timeSpent: Math.floor(interval / 1000), // Convert ms to seconds
      })
        .then(({ data }) => {
          onUpdate(data);
        });
    }

    return Promise.resolve();
  }

  intervalObject = setInterval(sendTimeSpent, interval);

  function stop() {
    stopwatch.value.state = 'stopped';
  }

  function start() {
    stopwatch.value.state = 'running';
  }

  function reset() {
    stop();
    clearInterval(interval);
    stopwatch.value.id = '';
  }

  const debouncedStop = debounce(() => {
    stop(); // Pause stopwatch after 3 minutes of inactivity
  }, 60000 * 3); // 3 minutes

  onMounted(() => {
    window.addEventListener('mousemove', start);
    window.addEventListener('mousemove', debouncedStop);
  });

  onUnmounted(() => {
    reset();
    window.removeEventListener('mousemove', start);
    window.removeEventListener('mousemove', debouncedStop);
  });

  return {
    stop,
    start,
    reset,
  };
}
