<template>
  <div class="choice choice--open" @click.stop="expandChoices">
    <div class="choice__current" :class="`choice__current--${currentChoice.status}`">
      <p class="choice__item" :class="`choice__item--${currentChoice.status}`">{{ currentChoice.text || 'En attente' }}</p>
      <img class="chevron" :class="{ rotate: opened }" src="@/assets/images/icons/i-chevron-down.svg" alt="chevron icon">
    </div>
    <ul
      v-click-outside="() => opened = false"
      class="choice__list"
      :class="{ hide: !opened}"
      ref="choices_list">
      <li
        v-for="(choice, index) in statesChoices"
        :key="choice.key"
        :class="({ 'disabled': index === 'VALIDATED' && disableValidated })"
        @click="pickChoice(choice.state)"
      >
        <p v-if="index !== demand.status" :class="`choice__item choice__item--${index}`">{{ choice.text }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ChoiceList',
  props: {
    demand: {
      type: Object,
      required: true,
    },
    tenderId: {
      type: String,
      required: true,
    },
    updateStatus: {
      type: Function,
      required: true,
    },
    disableValidated: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      opened: false,
      statesChoices: {
        VALIDATED: {
          text: 'Validée',
          state: 'VALIDATED',
        },
        HELP: {
          text: 'Demande d\'infos',
          state: 'HELP',
        },
        SEEN: {
          text: 'Vue',
          state: 'SEEN',
        },
        EXCLUDED: {
          text: 'Hors scope',
          state: 'EXCLUDED',
        }
      }
    }
  },
  computed: {
    currentChoice() {
      return {status: this.demand.status, ...this.statesChoices[this.demand.status]}
    }
  },
  methods: {
    expandChoices() {
      this.opened = !this.opened;
    },
    pickChoice(state) {
      if(this.disableValidated && state === 'NEW') {
        return
      }

      this.$axios.patch(`/tenders/${this.tenderId}/demands/${this.demand.id}`, { state })
      .then((response) => {
        this.updateStatus(response.data)
        this.notify()
      })
      .catch(() => {
        this.$notify({
          text: 'Le status de la demande n\'as pas pu être mis à jour.',
          type: 'error',
        });
      })
    },
    notify() {
      this.$notify({
        text: 'Statut du document mis à jour.'
      });
    }
  },
}
</script>

<style scoped>
.disabled {
  opacity: 0.3;
}
.choice__list .disabled .choice__item:hover {
  background: initial;
  cursor: initial;
}

.hide {
  display: none;
  transition: 500ms;
}
img.chevron {
  transition: transform .3s;
}
img.rotate {
  transform: rotate(180deg);
}
</style>
