<template>
  <div class="product-card card" v-bind:class="{ 'card--selected': selectable == false }" >
    <div class="card__content">
      <div class="card__left">
        <p
          v-if="product.status != 'ALTERNATIVE'"
          class="ribbon"
          :class="product.status">
          {{ $options.ribbonData[product.status].text }}
        </p>
        <img src="@/assets/images/manutan-logo-letters.png" v-if="product.brand === 'Manutan'">
        <div class="picture-price">
          <img :src="product.imageUrl" class="card__img" />
          <p class="card__price">
            Prix:
            <span>{{product.price}}€</span>
          </p>
        </div>
      </div>
      <div class="card__infos">
        <h4>
          {{product.name}}
          <div
            v-bind:class="{'inactive': selectable === true}"
            @click="$emit('validate', product)"
            class="btn btn--white selected"
          >
          <span v-if="!selectable">Sélectionné</span>
          <span v-else>Sélectionner</span>
          <span
            v-bind:class="{'inactive': selectable === true}"
            class="selected-icon">
            <span v-if="!selectable" class="selected-icon-inside" />
          </span>
          </div>
        </h4>
        <p class="card__item">
          Marque :
          <span v-bind:class="{ 'brand--highlight' : product.brand === 'Manutan' }">{{product.brand || 'Non fournie'}}</span>
        </p>
        <p class="card__item">
          Ref :
          <span>{{product.sku}}</span>
        </p>
        <p class="card__item">
          Ref fournisseur :
          <span>{{product.providerId || 'Non fournie'}}</span>
        </p>
        <p class="card__item">
          Packaging :
          <span>{{product.packaging}}</span>
        </p>
        <div class="tab">
          <div class="tab__head">
            <p v-bind:class="{ 'tab--selected' : tab === 'description' }" @click="setTab('description')">Description</p>
            <p v-bind:class="{ 'tab--selected' : tab === 'characteristics' }" @click="setTab('characteristics')">Caractéristiques</p>
            <p v-bind:class="{ 'tab--selected' : tab === 'explanation' }" @click="setTab('explanation')">Explication</p>
          </div>
          <div class="tab__item" v-bind:class="{ 'tab__item--shown' : tab === 'description' }">
            <ul>
              <li :key="i" v-for="(item, i) in product.description.split(',')">{{item}}</li>
            </ul>
          </div>
          <div class="tab__item" v-bind:class="{ 'tab__item--shown' : tab === 'characteristics' }">
            <ul>
              <li :key="i" v-for="(item, i) in product.technicalAttributes.split('|')">{{item}}</li>
            </ul>
          </div>
          <div class="tab__item" v-bind:class="{ 'tab__item--shown' : tab === 'explanation' }">
            Categories
            <ul>
              <li :key="i" v-for="(item, i) in product.categories.split('|')">{{item}}</li>
            </ul>
            Caractéristiques
            <ul>
              <li :key="i" v-for="(item, i) in product.characteristics.split('|')">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ribbonData = {
  SUGGESTED: {
    text: 'Produit suggéré par Golem.ai',
  },
  ALTERNATIVE: {
    text: 'Produit alternatif',
  },
  MANUAL: {
    text: 'Produit recherché manuellement ',
  },
  ALTERNATIVE_MANUTAN: {
    text: 'Produit Manutan'
  }
};

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 'description'
    }
  },
  methods: {
    setTab(value) {
      this.tab = value
    },
  },
  created() {
    this.$options.ribbonData = ribbonData;
  },
};
</script>

<style lang="scss" scoped>
.card__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    margin: 20px;
    width: 100px;
  }
}

p.ribbon {
  font-size: 14px;
  align-self: flex-start;
  padding: 5px 10px;
  border-radius: 0 6px 6px 0;

  &.ALTERNATIVE {
    background-color: #A9BBDE;
  }

  &.SUGGESTED {
    background-color: #D6D5EC;
  }

  &.MANUAL {
    background-color: rgba(#52a49f, .33);
  }

  &.ALTERNATIVE_MANUTAN {
    background-color: #7DDF6F;
  }
}

.picture-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.card__content > div:not(.card__infos) {
  padding: 0;
}

.brand--highlight {
  background-color: #FF742C;;
  color: white;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 11px;
  font-family: Poppins;
}

.selected {
  display: flex;
  justify-content: space-between;
}

.selected-icon {
  border: 1px solid #1E0338;
  border-radius: 100%;
  padding: 3px;
  margin-left: 10px;
  width: 10px;
  height: 10px;
}

.selected-icon-inside {
  background-color: #1E0338;
  border-radius: 100%;
  display: block;
  width: 10px;
  height: 10px;
}
.inactive {
  color: #786888;
  border-color: #786888;
}

</style>
