<template>
    <div
        class="toggle"
        :class="{ active: value }"
        @click="toggle">
        <div class="spacer" :class="{ expand: value }" />
        <div class="inner" />
    </div>
</template>

<script>
    import { defineComponent } from '@vue/composition-api';
    export default defineComponent({
        name: 'Toggle',
        props: {
            value: {
                type: Boolean,
                required: true,
            },
        },
        setup(props, { emit }) {
            function toggle() {
                emit('update:value', !props.value);
            }
        return {
            toggle,
        };
        },
    });
</script>

<style lang="scss" scoped>
    .toggle {
        cursor: pointer;
        width: 48px;
        padding: 5px 8px;
        border-radius: 100px;
        background-color: #ccc;
        transition: all .2s;
        display: flex;
        &.active {
            background-color: #0B2956;
        }
    }
    .spacer {
        transition: all .2s;
        &.expand {
            flex-grow: 1;
        }
    }
    .inner {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 100%;
    }
</style>
