<template>
  <div v-if="labels" id="p-srvtest-labels" class="answerLabelling">
    <div v-for="(label,i) in labels" :key="label.type + i">
      <div v-if="label.expression.trim() !== ''" class="c-srvtest-label" :class="{'c-srvtest-label-withDesc': label.type !== 'nothing'}">
        <div class="c-srvtest-label-desc">
          <div class="c-srvtest-label-type fixHeight" :style="`color: ${styleColor(label.archetype || label.type)}`">
            {{ label.type !== 'nothing' ? label.type : '' }} {{ label.archetype ? `(${label.archetype})`: "" }} 
          </div>
          <div class="c-srvtest-label-id fixHeight">
            {{ labelToText(label) || "" }}
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="c-srvtest-label-letters test"
             :style="`text-decoration-color: ${styleColor(label.archetype || label.type)}`"
             :class="{underlined: label.type !== 'nothing'}"
             v-html="expression(label)"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestServerPhrase",
  props: {
    labels: { type: Array, required: true }
  },

  data: () => ({
    scheme: [
      "#f9af19",
      "#e27e5a",
      "#D32F2F",
      "#10d40f",
      "#817bb5",
      "#619d3c",
      "#26C6DA",
      "#00C853",
      "#C51162"
    ],
    colors: {
      undefined: "#a91cbc"
    },
    colorIndex: 0
  }),

  methods: {
    labelToText(label) {
      if (label.type === "archetype") {
        return label.used_parameter;
      }
      return "";
    },

    styleColor(archetype) {
      if (this.colors[archetype]) {
        return this.colors[archetype];
      } else {
        this.colors[archetype] = this.scheme[
          this.colorIndex++ % this.scheme.length
        ];
        return this.colors[archetype];
      }
    },

    expression(label) {
      if (!label.corrected) {
        return label.expression;
      }
      let ret = "";
      let co = this.styleColor(label.archetype || label.type);
      const color = `style="color: ${co}"`;
      const strike = `style="text-decoration-color: ${co};
      								color: rgba( 0, 0, 0, .4 );
      								font-weight: normal;
      								text-decoration-line: line-through;"`;

      this.diff(label.expression.trim(), label.corrected.trim()).forEach(
        function(diffArr) {
          if (diffArr[0]) {
            if (diffArr[0] > 0) {
              ret += `<span ${color}>${diffArr[3]}</span>`;
            } else {
              ret += `<span ${strike}>${diffArr[3]}</span>`;
            }
          } else {
            ret += `<span>${diffArr[3]}</span>`;
          }
        }
      );
      return ret;
    },

    /*________ 			Diff			 ________ */

    diff(a, b) {
      var i,
        j,
        v,
        tab,
        beginPart,
        beginPartLen,
        endingPart,
        endingPartLen,
        iX,
        iA = 0,
        iB = 0,
        seq = [],
        arr = [],
        aLen = a.length,
        bLen = b.length,
        minLen = Math.min(aLen, bLen);

      // Convert the two array-likes into true arrays
      a = Array.from(a);
      b = Array.from(b);

      // calcul the size of the identical begin part
      for (i = 0; i < minLen; ++i) {
        if (a[i] !== b[i]) {
          break;
        }
      }

      // trim the size of the identical ending part
      for (j = 0; j < minLen - i; ++j) {
        if (a[aLen - 1 - j] !== b[bLen - 1 - j]) {
          break;
        }
      }

      // cut the arrays to keep only the change
      endingPart = a.splice(aLen - j);
      b.splice(bLen - j);
      endingPartLen = endingPart.length;
      beginPart = a.splice(0, i);
      b.splice(0, i);
      beginPartLen = beginPart.length;
      aLen = a.length;
      bLen = b.length;

      // create the table
      tab = new Array(aLen + 1);
      for (i = aLen; i >= 0; --i) {
        tab[i] = new Array(bLen + 1);
        tab[i][0] = 0;
      }
      for (i = bLen; i > 0; --i) {
        tab[0][i] = 0;
      }

      // fill the table
      for (i = 1; tab[i]; ++i) {
        for (j = 1; j <= bLen; ++j) {
          tab[i][j] =
            a[i - 1] === b[j - 1]
              ? 1 + tab[i - 1][j - 1]
              : Math.max(tab[i - 1][j], tab[i][j - 1]);
        }
      }

      // backtrack
      i = aLen;
      j = bLen;
      for (; i > 0; --i) {
        v = tab[i][j];
        while (j > 0 && tab[i][j - 1] === v) {
          --j;
        }
        if (tab[i - 1][j] < tab[i][j]) {
          seq[tab[i][j] - 1] = a[i - 1];
        }
      }

      // write the begin identical part into the diff
      if (beginPartLen > 0) {
        for (i = 0; i < beginPartLen; ++i) {
          arr.push([0, i, i, beginPart[i]]);
        }
      }

      // write the middle part with the changes
      iX = iB = iA = 0;
      while (iA < aLen && iB < bLen) {
        if (a[iA] !== seq[iX]) {
          arr.push([-1, beginPartLen + iA, -1, a[iA]]);
          ++iA;
        } else if (b[iB] !== seq[iX]) {
          arr.push([+1, -1, beginPartLen + iB, b[iB]]);
          ++iB;
        } else if (a[iA] === b[iB]) {
          arr.push([0, beginPartLen + iA, beginPartLen + iB, a[iA]]);
          ++iA;
          ++iB;
          ++iX;
        }
      }
      for (; iA < aLen; ++iA) {
        arr.push([-1, beginPartLen + iA, -1, a[iA]]);
      }
      for (; iB < bLen; ++iB) {
        arr.push([+1, -1, beginPartLen + iB, b[iB]]);
      }

      // write the ending identical part into the diff
      for (i = 0; i < endingPartLen; ++i) {
        arr.push([
          0,
          beginPartLen + iA + i,
          beginPartLen + iB + i,
          endingPart[i]
        ]);
      }

      return arr;
    }
  }
};
</script>

<style scoped>
.fixHeight {
  height: 23px;
}

.answerLabelling {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #6c6c6c;
}
#p-srvtest-labels {
  margin: 0 30px;
}
.c-srvtest-label {
  text-align: center;
  margin: 5px 10px 10px 10px;
}
.c-srvtest-label-letters, .c-srvtest-label-type {
    font-weight: bold;
}
.c-srvtest-label-letters.underlined {
  text-decoration: underline;
}
</style>
