<template>
  <!-- Start demand content -->
  <div class="content">
    <div class="top">
      <div>
        <router-link :to="goBack" class="btn btn--blue">Retourner au document</router-link>
      </div>
      <div class="top__navigation">
        <div>
          <router-link v-if="prevDemand" :to="{
            name: 'demand',
            params: { tender: tenderId, demand: prevDemand },
            query : { SORT_FIELD: sortField, SORT: sort },
          }" >
            <button class="top__previous"></button>
          </router-link>
          <div class="top__name">
            <p>{{demandNumber}} sur {{total}}</p>
            <p>Demande initiale</p>
            <div>
              <span v-if="demand">{{ demand.content }}</span>
            </div>
          </div>
          <router-link v-if="nextDemand" :to="{
            name: 'demand',
            params: { tender: tenderId, demand: nextDemand },
            query : { SORT_FIELD: sortField, SORT: sort },
          }">
            <button class="top__next"></button>
          </router-link>
        </div>
      </div>
      <div class="top__references" />
    </div>

    <div class="demand-header align--items--center space--between">
      <div />
      <div class="labels">
        <GolemLabelling v-if="demand && debugMode" :labels="demand.labels" />
      </div>
      <div class="space--between debug-switch">
        <label>Afficher les détails</label>
        <Toggle :value.sync="debugMode" />
      </div>
    </div>

    <div v-if="demandLoading" class="loading"><span class="lds-dual-ring"></span></div>

    <div class="selection" v-else>
      <div>
        <div class="selection__title">
          <h3 v-if="!demand.selectedProduct || demand.selectedProduct.status !== 'MANUAL'" >Produit sélectionné</h3>
          <h3 v-else>Sélection manuelle</h3>

          <div class="toggle-container" >
            <label for="exact-response">Réponse exacte: </label>
            <input type="checkbox" id="exact-response" @change="togglePerfectlyFulfilled" :checked="demand.perfectlyFulfilled" />
          </div>

          <ChoiceList
            :updateStatus="updateStatus"
            :demand="demand"
            :tenderId="tenderId"
            :disableValidated="!demand.selectedProduct && !demand.suggestedProduct"
          />
        </div>

        <!-- Start of product card -->
        <transition name="fade" mode="out-in">
          <ProductCard
            :key="demand.suggestedProduct.id"
            v-if="!demand.selectedProduct && demand.suggestedProduct"
            :product="demand.suggestedProduct"/>
          <ProductCard
            :key="demand.selectedProduct.id"
            v-else-if="demand.selectedProduct"
            :product="demand.selectedProduct"/>
          <div v-else key="error">
            <p>
              <svg class="error-svg" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8.75 11.75H7.25V10.25H8.75V11.75ZM8.75 8.75H7.25V4.25H8.75V8.75Z" fill="#F55F44"/>
              </svg>
              Aucun produit ne correspond
            </p>
          </div>
        </transition>
        <div>
          <label for="input-comment">Commentaire: </label>
          <div class="input-row">
            <input
              class="input-comment"
              id="input-comment"
              type="text"
              v-model.lazy="demand.comment"
              @change="updateComment">
          </div>
        </div>
        <div class="outside__form">
          <p>Votre produit est en dehors de cette sélection ? Recherchez avec la référence.</p>
          <div class="outside__container">
            <input v-model="manualSearch" @keypress.enter="validateManually(manualSearch)" type="text" placeholder="Référence produit">
            <!-- @TODO NOTIF ON SUCESS AND ERROR -->
            <button
              @click="validateManually(manualSearch)"
              :disabled="searchingManually"
              id="search-manually-btn"
              class="btn btn--blue">
              <span v-if="searchingManually" class="lds-dual-ring small"></span>
              <span :class="{ invisible: searchingManually }">Rechercher</span>
            </button>
          </div>
        </div>
        <!-- End of product card -->
      </div>
      <div>
        <div class="selection__title">
          <h3>Produits suggérés en alternative</h3>
        </div>
        <ProductCard
          v-for="product in getOrderedAlternatives"
          :key="product.id"
          selectable
          @validate="validate(product)"
          :product="product"/>
      </div>
    </div>
  </div>
  <!-- End demand content -->
</template>

<script>
import { computed } from '@vue/composition-api';
import ChoiceList from '@/components/ChoiceList';
import ProductCard from '@/components/ProductCard';
import Toggle from '@/components/Toggle';
import GolemLabelling from '@/components/GolemLabelling';
import useStopwatch from '@/hooks/stopwatch';

export default {
  name: 'Demand',
  components: {
    ChoiceList,
    ProductCard,
    Toggle,
    GolemLabelling,
  },
  data: () => ({
    demand: null,
    nextDemand: null,
    prevDemand: null,
    demandNumber: 0,
    total: 0,
    demandLoading: false,
    searchingManually: false,
    tenderId: null,
    manualSearch: '',
    debugMode: false,
  }),
  setup(props, { root }) {
    const tenderId = computed(() => root.$route.params.tender);
    useStopwatch(tenderId.value, 5000, root.$store.getters['auth/userInfo']['username']);

    const sortField = computed(() => root.$route.query['SORT_FIELD']);
    const sort = computed(() => root.$route.query['SORT']);

    return {
      sortField,
      sort,
    }
  },
  created() {
    this.tenderId = this.$route.params.tender
    this.getDemand(this.$route.params.tender, this.$route.params.demand, this.$route.query['SORT_FIELD'], this.$route.query['SORT'])
  },
  beforeRouteUpdate (to, from, next) {
    this.tenderId = to.params.tender
    this.manualSearch = '';
    this.getDemand(to.params.tender, to.params.demand, this.$route.query['SORT_FIELD'], this.$route.query['SORT'])
    next()
  },
  computed: {
    goBack() {
      return {
        name: 'list',
        params: {
          ...this.$route.params,
        },
        query: {
          ...this.$route.query,
        },
      };
    },
    getOrderedAlternatives() {
      if (this.demand === null) {
        return []
      }
      let nonOrdered = this.demand.alternativeProducts
      if (this.demand.selectedProduct && this.demand.suggestedProduct && this.demand.selectedProduct.status !== 'SUGGESTED') {
        nonOrdered = [...nonOrdered, this.demand.suggestedProduct]
      }
      return nonOrdered.sort((a, b) => {
        if (a.brand === b.brand) {
          if (a.status === "SUGGESTED") {
            return -1
          }
          return 1
        }
        if (a.brand === 'Manutan') {
          return -1
        }
        if (b.brand === 'Manutan') {
          return 1
        }
        if (a.status === "SUGGESTED") {
            return -1
        }
        return 1
      })
    },
  },
  methods: {
    getDemand(tenderId, demandId, sortField, sort) {
      if (sortField === undefined) {
        sortField = 'demand.number';
      }
      if (sort === undefined) {
        sort = 'ASC';
      }
      this.demandLoading = true
      return this.$axios.get(`/tenders/${tenderId}/demands/${demandId}?SORT_FIELD=${sortField}&SORT=${sort}`)
        .then(response => {
          this.demand = this.formatDemand(JSON.parse(response.data.demand))
          this.nextDemand = response.data.nextDemand
          this.prevDemand = response.data.prevDemand
          this.demandNumber = response.data.demandNumber
          this.total = response.data.total
          this.demandLoading = false
        })
    },
    updateStatus(updatedDemand) {
      this.demand = this.formatDemand(updatedDemand);
    },
    formatDemand(demand) {
      demand.alternativeProducts = Object.values(demand.products)
        .filter(product => product.status === 'ALTERNATIVE' && (demand.selectedProduct === null || product.id !== demand.selectedProduct.id))
      demand.selectedProduct = Object.values(demand.products)
        .find(product => demand.selectedProduct !== null && product.id === demand.selectedProduct.id)
      demand.suggestedProduct = Object.values(demand.products)
        .find(product => product.status === 'SUGGESTED')

      demand.labels = JSON.parse(demand.labels || '[]')
        .filter(label => label.expression.trim() !== '');

      return demand
    },
    validate(product) {
      this.$axios.patch(`/tenders/${this.tenderId}/demands/${this.demand.id}`, { product: product.sku })
      .then((response) => {
        this.demand = this.formatDemand(response.data)
      })
    },
    validateManually(sku) {
      this.searchingManually = true;
      document.activeElement.blur();
      this.$axios.patch(`/tenders/${this.tenderId}/demands/${this.demand.id}`, { product: sku.toUpperCase() })
        .then((response) => {
          this.demand = this.formatDemand(response.data)
          this.$notify({
            type: 'success',
            text: 'Demande complétée depuis votre recherche manuelle',
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Nous n\'avons pas pu retrouver la référence',
          });
        })
        .finally(() => {
          this.searchingManually = false;
        });
    },
    togglePerfectlyFulfilled(event) {
      this.$axios.patch(`/tenders/${this.tenderId}/demands/${this.demand.id}`, { perfectlyFulfilled: event.target.checked })
        .then((response) => {
          this.demand = this.formatDemand(response.data)
          this.$notify({
            type: 'success',
            text: 'Votre modification a bien été prise en compte',
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Nous n\'avons pas réussi à prendre votre modification en compte',
          });
        })
    },
    updateComment() {
      this.$axios.patch(`/tenders/${this.tenderId}/demands/${this.demand.id}`, { comment: this.demand.comment })
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Le commentaire de la demande à été mis à jour !',
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Le commentaire de la demande n\'a pas pu être mis à jour',
          })
        });
    },
  }
}
</script>

<style scoped src="../assets/css/demand.css"></style>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

a.btn.btn--blue {
  width: fit-content;
  text-decoration: none;
  color: #fff;
}
.tab__item ul {
  max-height: 200px;
  overflow: scroll;
}

.demand-header {
  margin: 20px 0;
  padding: 0 45px;
  flex-direction: row;
  display: flex;
}

.top__card {
  border: 1px solid #C9C9C9;
  border-radius: 6px;
  padding: 12px;
  margin-top: 10px;
}
.top__card h4 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color:#757575;
}
.top__card p {
  font-size: 16px;
  margin: 5px 0 0;
  max-width: 300px;
}

.outside__form{
  border-top: 1px solid #C9C9C9;
  font-size: 14px;
  margin-top: 30px;
  padding-top: 15px;
}

.error-svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.selection__title > div:first-child {
  flex: 1;
}

.selection__title h3 {
  /* margin: 20px 0; */
}

.selection__title .btn {
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  color: #0B2956;
  padding: 8px 0;
  margin: 0 0 15px 10px;
  border-radius: 0;
  border-bottom: 1px solid #0B2956;
}
.selection__title .btn img {
  margin-right: 10px;
}

button:disabled {
  background-color: #bbb;
  box-shadow: inherit;
}

button#search-manually-btn {
  position: relative;
}

span.invisible {
  opacity: 0;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 100px;
}
.loading .lds-dual-ring::after {
  border-color: black transparent black transparent
}
.lds-dual-ring {
  margin-bottom: 5px;
}

.toggle-container {
  display: flex;
  color: #0B2956;
  align-items: center;
}

.toggle-container input {
  height: 25px;
  width: 25px;
  margin: 0 5px;
  padding: 0;
}

.input-comment {
  -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    font-size: 13px;
    width: 100%;
}

.debug-switch label {
  margin-right: 8px;
  white-space: nowrap;
}
</style>
